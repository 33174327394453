@import "../../assets/scss/common/variables";

.modal {
  width: 330px;
  background-color: $eight;
  border-radius: 25px;
  // box-shadow: 0px 0px 60px -23px rgb(206, 206, 206);
  padding: 28px 20px;

  @media only screen and (max-width: $break_point_xs) {
    max-width: 320px;
  }

  .status {
    padding: 20px 0px;
    text-align: center;

    .success {
      color: $colorSuccess;
    }

    .failed {
      color: $primary;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      min-width: 84px;
      font-weight: bold;
    }
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  content: " ";
  align-items: center;
  justify-content: center;
}
