@import "../common/variables";

.nest-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bird-img-wrapper {
  max-width: 140px;
  width: 140px;
  position: relative;

  .eagle-1 {
    top: -20px;
    position: absolute;
    left: -120%;
    width: 200px;
    display: none;

    span {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      font-size: 25px;

      @media only screen and (max-width: $break_point_xxl) {
        font-size: 16px;
      }

      @media only screen and (max-width: $break_point_md) {
        font-size: 14px;
      }

      @media only screen and (max-width: $break_point_sm) {
        font-size: 10px;
      }
    }

    @media only screen and (max-width: $break_point_xxl) {
      width: 100px;
    }

    @media only screen and (max-width: $break_point_md) {
      width: 100px;
      top: -25px;
      left: -200%;
    }

    @media only screen and (max-width: $break_point_sm) {
      width: 60px;
      top: -15px;
      left: -120%;
    }
  }

  .eagle-2 {
    top: -20px;
    position: absolute;
    left: -120%;
    width: 200px;
    display: none;

    span {
      position: absolute;
      top: 50%;
      right: 60%;
      transform: rotateY(180deg) translate(-50%, -50%);
      font-size: 25px;

      @media only screen and (max-width: $break_point_xxl) {
        font-size: 16px;
      }

      @media only screen and (max-width: $break_point_md) {
        font-size: 14px;
        right: 60%;
      }

      @media only screen and (max-width: $break_point_sm) {
        font-size: 10px;
        right: 60%;
      }
    }

    @media only screen and (max-width: $break_point_xxl) {
      width: 100px;
    }

    @media only screen and (max-width: $break_point_md) {
      width: 100px;
      top: -25px;
      left: -200%;
    }

    @media only screen and (max-width: $break_point_sm) {
      width: 60px;
      top: -15px;
      left: -120%;
    }
  }

  img {
    width: 100%;
  }

  &.rotate-left {
    transform: rotateY(180deg);
    margin-right: 0px;
    margin-left: -10px;
  }

  &:hover {
    .eagle-1 {
      display: block;
    }

    .eagle-2 {
      display: block;
    }
  }

  @media only screen and (max-width: 1750px) {
    max-width: 95px;
    width: 95px;
  }

  @media only screen and (max-width: $break_point_xxl) {
    max-width: 90px;
    margin-right: -0px;
    width: 90px;
  }

  @media only screen and (max-width: $break_point_xl) {
    max-width: 70px;
    width: 70px;
  }

  @media only screen and (max-width: $break_point_lg) {
    max-width: 70px;
    width: 70px;
  }

  @media only screen and (max-width: $break_point_md) {
    max-width: 45px;
    width: 45px;
  }

  @media only screen and (max-width: $break_point_xs) {
    max-width: 40px;
    width: 40px;
  }
}

.eag {
  width: 40px;
  height: 50px;

  @media only screen and (max-width: $break_point_md) {
    margin-left: -10px;
    width: auto;
    height: auto;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 18px;
  }

  .img-fluid {
    width: 100%;
  }
}

.nest-wrapper {
  width: 270px;

  @media only screen and (max-width: 1750px) {
    max-width: 250px;
  }

  @media only screen and (max-width: $break_point_xxl) {
    max-width: 230px;
  }

  @media only screen and (max-width: $break_point_xl) {
    max-width: 175px;
  }

  @media only screen and (max-width: $break_point_sm) {
    max-width: 105px;
  }
}
