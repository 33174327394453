@import "../common/variables";

.cmn-bird-reward {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e1e1e1;
  border-radius: 50%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  img {
    max-width: 100px;
    border-radius: 50%;
  }

  @media only screen and (max-width: $break_point_xxl) {
    width: 110px;
    height: 110px;
    min-width: 110px;
    min-height: 110px;
  }
}

.cmn-bird-trophy-block {
  padding: 0px 50px;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: $break_point_xxl) {
    padding: 0px 15px;
  }

  @media only screen and (max-width: $break_point_md) {
    margin: 15px 0;
  }

  .border-block {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translate(0, -50%);
    max-height: 80%;
    overflow: hidden;

    img {
      @media only screen and (max-width: $break_point_xxl) {
        width: 10px;
      }

      @media only screen and (max-width: $break_point_md) {
        display: none;
      }
    }

    @media only screen and (max-width: $break_point_md) {
      border-top: 2px solid;
      left: 0px;
      width: 100%;
      transform: translate(0, 0);
      top: -10px;
    }
  }
}

.cmn-trophy-block {
  position: absolute;
  left: -10px;
  top: 5px;

  img {
    width: 35px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 35px;
    }
  }
}
