@import "../common/variables";
#eagle-block {
  background-image: url("../../images/item-background.png");
  padding: 15px 15px 30px;
  background-size: 100% 390px;
  margin: 15px;
  width: calc(25% - 30px);
  background-repeat: no-repeat;
  height: 390px;

  @media only screen and (max-width: $break_point_xxl) {
    width: calc(33.33% - 30px);
  }

  @media only screen and (max-width: $break_point_xxl) {
    width: calc(33.33% - 30px);
  }

  @media only screen and (max-width: $break_point_lg) {
    width: calc(33.33% - 30px);
    background-size: 100% 270px;
    height: 270px;
  }

  @media only screen and (max-width: $break_point_md) {
    width: calc(50% - 30px);
    background-size: 100% 270px;
    height: 270px;
  }

  @media only screen and (max-width: $break_point_xs) {
    width: calc(100% - 10px);
    background-size: 100% 270px;
    height: 270px;
    margin: 5px;
    padding: 15px 30px 30px;
  }

  @media only screen and (max-width: 390px) {
    width: 100%;
  }

  &.disabled {
    opacity: 0.7;
    filter: blur(2px);
    pointer-events: none;
  }

  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ct-block-2 {
    margin: 0 30px;
    height: auto;
    max-height: 80px;
    min-height: 70px;

    button {
      width: 100%;
      margin-top: 15px;
    }

    @media only screen and (max-width: $break_point_sm) {
      display: flex;
      width: auto;

      @media only screen and (max-width: 390px) {
        margin: 0px 10px;
      }
    }
  }
  .eagle-img {
    width: 80%;
    height: 80%;
    @media only screen and (max-width: $break_point_lg) {
      width: 150px;
      height: 80%;
    }
  }
  .img-eagle-wrap {
    margin: 0px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background-image: url("../../images/shop-image-background.png");
    background-repeat: no-repeat;
    background-size: 100% 215px;
    width: calc(100% - 30px);
    height: 215px;

    @media only screen and (max-width: $break_point_lg) {
      background-size: 100% 150px;
      height: 150px;
    }

    @media only screen and (max-width: $break_point_md) {
      background-size: 100% 160px;
      height: 160px;
    }

    @media only screen and (max-width: $break_point_sm) {
      background-size: 100% 150px;
      height: 150px;
    }
  }
}
