@import "../common/variables";

.title-block {
  background-image: url("../../images/item-header.png");
  background-size: 100% 150px;
  height: 150px;
  background-repeat: no-repeat;
  display: flex;
  padding: 20px 70px;

  @media only screen and (max-width: $break_point_xxl) {
    background-size: 100% 125px;
    height: 125px;
  }

  @media only screen and (max-width: $break_point_md) {
    padding: 20px 30px;
  }
}

.common-inner-lap {
  width: 100%;
  padding: 15px 35px;

  @media only screen and (max-width: $break_point_lg) {
    padding: 15px 25px;
  }
}

.shop-main-wrapper {
  .eagle-shop {
    display: flex;
    flex-wrap: wrap;
  }

  .eagle-block {
    background-image: url("../../images/item-background.png");
    padding: 15px 15px 30px;
    background-size: 100% 550px;
    margin: 15px;
    width: calc(25% - 30px);
    background-repeat: no-repeat;
    height: 550px;

    @media only screen and (max-width: $break_point_xxl) {
      width: calc(33.33% - 30px);
    }

    @media only screen and (max-width: $break_point_xxl) {
      width: calc(33.33% - 30px);
    }

    @media only screen and (max-width: $break_point_lg) {
      width: calc(33.33% - 30px);
      background-size: 100% 370px;
      height: 370px;
    }

    @media only screen and (max-width: $break_point_md) {
      width: calc(50% - 30px);
      background-size: 100% 340px;
      height: 340px;
    }

    @media only screen and (max-width: $break_point_xs) {
      width: calc(100% - 10px);
      background-size: 100% 304px;
      height: 304px;
      margin: 5px;
      padding: 15px 30px 30px;
    }

    @media only screen and (max-width: 390px) {
      width: 100%;
    }
    @media only screen and (max-width: 332px) {
      background-size: 100% 320px;
      height: 320px;
    }

    &.disabled {
      opacity: 0.7;
      filter: blur(2px);
      pointer-events: none;
    }
  }

  .eagle-img {
    width: 150px;
    height: 150px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 100px;
      height: 100px;
    }

    @media only screen and (max-width: $break_point_md) {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: $break_point_sm) {
      width: 60px;
      height: 60px;
    }
  }

  .img-eagle-wrap {
    margin: 0px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background-image: url("../../images/shop-image-background.png");
    background-repeat: no-repeat;
    background-size: 100% 215px;
    width: calc(100% - 30px);
    height: 215px;

    @media only screen and (max-width: $break_point_lg) {
      background-size: 100% 150px;
      height: 150px;
    }

    @media only screen and (max-width: $break_point_md) {
      background-size: 100% 100px;
      height: 100px;
    }

    @media only screen and (max-width: $break_point_sm) {
      background-size: 100% 100px;
      height: 100px;
    }
  }

  .e-title {
    padding-top: 10px;
    margin-bottom: 0px;

    @media only screen and (max-width: $break_point_sm) {
      padding-top: 0;
    }
  }

  .e-sub-title {
    font-weight: 400;
    max-height: 70px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;

    @media only screen and (max-width: $break_point_lg) {
      max-height: 50px;
      height: 50px;
    }
  }

  .price-block {
    display: flex;
    justify-content: flex-start;
    margin-top: 0px;
    flex-grow: 1;

    @media only screen and (max-width: $break_point_sm) {
      margin-top: 0;
      justify-content: flex-start;
    }

    .second {
      @media only screen and (max-width: $break_point_sm) {
        display: none;
      }
    }
  }

  .block-1 {
    display: inline-flex;
  }

  .block-2 {
    display: inline-flex;
    padding-left: 15px;
    flex-direction: column;
  }
}

.ct-block-2 {
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  min-height: 190px;
  max-height: 233px;
  height: 100%;

  & > div {
    margin-top: auto;
  }

  @media only screen and (max-width: $break_point_lg) {
    min-height: 100px;
    max-height: 130px;
  }

  @media only screen and (max-width: $break_point_md) {
    min-height: 120px;
    max-height: 156px;
    height: 100%;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 100%;
    margin: 0px;
    min-height: 120px;
    max-height: 150px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: 575px) {
    min-height: 120px;
    max-height: 120px;
  }
}

.shop-nft-title {
  margin: 10px;
}
