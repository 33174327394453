.filterTransaction {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  max-width: 200px;
  margin-left: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;

  input[type="radio"] {
    & + span {
      font-size: 16px;
    }

    &:checked + span {
      font-weight: bold;
    }

    &:disabled + span {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
