@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/mixins";

// make text white on night theme
.night-theme .nfy-block.desktop {
  h3 {
    color: white;
  }
}

.nfy-block {
  margin-top: 35px;
  position: relative;

  // TODO: fix this ugly hack
  .inventory-tab {
    h3 {
      opacity: 0.7;
      cursor: pointer;

      &.active {
        opacity: 1;
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }

  .nft-ul {
    margin-top: 10px;
    max-height: 250px;
    min-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    li {
      padding: 10px;
      height: fit-content;
    }

    @include customScrollBar;
  }

  &.desktop {
    display: block;
    max-height: 480px;
    overflow: auto;

    @media only screen and (max-width: $break_point_sm) {
      display: none;
    }
  }

  &.ipad {
    display: none;
    position: absolute;
    z-index: 20;
    width: 265px;
    left: 50%;
    bottom: 30px;
    background-color: $ninth;
    padding: 10px;
    transform: translate(-50%, 0);

    .nft-ul {
      min-height: 130px;
    }

    @media only screen and (max-width: $break_point_lg) {
      display: block;
    }
  }
}

.nft-ul {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  justify-content: start;
  align-items: flex-start;

  div {
    position: relative;
    background-color: rgba(264, 264, 264, 0.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    width: calc(25% - 16px);

    img {
      width: 64px;
      height: 64px;

      @media only screen and (max-width: $break_point_xxl) {
        width: 40px;
        height: 40px;
      }
    }

    .closeIcon {
      position: absolute;
      cursor: pointer;
      background: white;
      border-radius: 50%;
      right: -8px;
      top: -5px;
      z-index: 20;
    }
  }
}

.custom-nft-drop {
  margin: 15px auto;
  position: relative;
  display: none;
  margin-top: 40px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: 0;
    margin: 0 auto;

    .cont {
      margin: 0 10px;
    }
  }

  @media only screen and (max-width: $break_point_sm) {
    display: flex;
  }
}

.mobile-show-counter {
  display: none;

  @media only screen and (max-width: $break_point_sm) {
    display: block;
    margin: 0 auto 15px;
  }
}

.mobile-hide-counter {
  display: block;

  @media only screen and (max-width: $break_point_sm) {
    display: none;
  }
}

.menu-it {
  &.d-block {
    display: block;
  }

  &.d-none {
    display: none;
  }
}
