@import "../common/variables";

.title-block {
  background-image: url("../../images/item-header.png");
  background-size: 100% 150px;
  height: 150px;
  background-repeat: no-repeat;
  display: flex;
  padding: 20px 70px;

  @media only screen and (max-width: $break_point_xxl) {
    background-size: 100% 125px;
    height: 125px;
  }

  @media only screen and (max-width: $break_point_md) {
    padding: 20px 30px;
  }
}

.common-inner-lap {
  width: 100%;
  padding: 15px 35px;

  @media only screen and (max-width: $break_point_lg) {
    padding: 15px 25px;
  }
}

.inner-tl {
  display: flex;

  img {
    margin-right: 10px;
    height: 30px;
  }
}

.main-block {
  display: flex;
  flex-direction: column;
  .inner {
    background: rgba(252, 223, 179, 0.8);
    border-radius: 15px;
    padding: 25px 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  button {
    margin: 0px auto;
    min-width: 100px;
  }
}

.first-text-blk {
  display: flex;
  flex-direction: column;
}

.first-block-wrapper {
  display: inline-flex;
}

.text-blk {
  padding: 15px;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 30px;

  p {
    padding-bottom: 5px;
  }
}

.bird-wrapper-block {
  width: 250px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  min-height: 150px;

  img {
    width: 111px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 90px;
    }

    @media only screen and (max-width: $break_point_xs) {
      width: 70px;
    }
  }

  @media only screen and (max-width: $break_point_xxl) {
    min-height: 125px;
    width: 190px;
  }

  @media only screen and (max-width: $break_point_xs) {
    min-height: 100px;
    width: 145px;
  }

  .kg-2 {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: -10px;
  }

  .kg-1 {
    margin-right: -10px;
  }
}

.duration-wrapper-block {
  margin: 0 25px;

  h6 {
    text-align: center;
  }

  @media only screen and (max-width: $break_point_md) {
    margin: 15px 25px;
  }
}

.duration-block {
  background: #ffffff;
  border: 1px solid #7987ff;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
  width: 240px;
  justify-content: space-between;

  span {
    padding: 0 10px;
  }

  @media only screen and (max-width: $break_point_xxl) {
    width: 190px;
  }
}

.duration-block-timer {
  width: 240px;
  display: flex;
  justify-content: space-between;

  h5 {
    font-weight: 700;
  }

  @media only screen and (max-width: $break_point_xxl) {
    width: 190px;
  }
}

.position-used {
  flex-grow: 1;
  max-width: 30%;

  @media only screen and (max-width: $break_point_md) {
    max-width: 100%;
    min-height: 100px;
    width: 100%;
  }
}

.bird-reward {
  width: 192px;
  height: 192px;
  min-width: 192px;
  min-height: 192px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e1e1e1;
  border-radius: 50%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  img {
    max-width: 100px;
    border-radius: 50%;
  }

  @media only screen and (max-width: $break_point_xxl) {
    width: 110px;
    height: 110px;
    min-width: 110px;
    min-height: 110px;
  }
}

.trophy-block {
  position: absolute;
  left: -10px;
  top: 5px;

  img {
    width: 55px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 30px;
    }
  }
}

.bird-trophy-block {
  padding: 0px 50px;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: $break_point_xxl) {
    padding: 0px 15px;
  }

  @media only screen and (max-width: $break_point_md) {
    margin: 15px 0;
  }

  .border-block {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translate(0, -50%);

    img {
      @media only screen and (max-width: $break_point_xxl) {
        width: 10px;
      }

      @media only screen and (max-width: $break_point_md) {
        display: none;
      }
    }

    @media only screen and (max-width: $break_point_md) {
      border-top: 2px solid;
      left: 0px;
      width: 100%;
      transform: translate(0, 0);
      top: -10px;
    }
  }
}
