@import "../common/variables";

.title-block {
  background-image: url("../../images/item-header.png");
  background-size: 100% 150px;
  height: 150px;
  background-repeat: no-repeat;
  display: flex;
  padding: 20px 70px;

  @media only screen and (max-width: $break_point_xxl) {
    background-size: 100% 125px;
    height: 125px;
  }

  @media only screen and (max-width: $break_point_md) {
    padding: 20px 30px;
  }
}

.common-inner-lap {
  width: 100%;
  padding: 15px 35px;

  @media only screen and (max-width: $break_point_lg) {
    padding: 15px 25px;
  }
}

.swap-main-wrapper {
  .eagle-shop {
    display: flex;
    flex-wrap: wrap;
  }

  .e-title {
    padding-top: 10px;
    margin-bottom: 0px;

    @media only screen and (max-width: $break_point_sm) {
      padding-top: 0;
    }
  }

  .e-sub-title {
    font-weight: 400;
    max-height: 70px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;

    @media only screen and (max-width: $break_point_lg) {
      max-height: 50px;
      height: 50px;
    }
  }

  .block-1 {
    display: inline-flex;
  }

  .block-2 {
    display: inline-flex;
    padding-left: 15px;
    flex-direction: column;
  }
}
