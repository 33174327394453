@import "../../assets/scss/common/variables";

.transactionBlock {
  background: $secondary;
  border-radius: 15px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  &.saffron {
    background-color: #fcdfb3;
  }

  &.green {
    background-color: #d4f3c9;
  }

  &.dark-green {
    background-color: #cff473;
  }

  &.yellow {
    background-color: #f5f38e;
  }
}

.trImgWrapper {
  display: inline-flex;

  .trImgBlock {
    width: 125px;
    height: 125px;
    background-color: #ffffff;
    border-radius: 10px;

    img {
      width: 110px;

      @media only screen and (max-width: $break_point_md) {
        width: 60px;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $break_point_md) {
      width: 90px;
      height: 90px;
    }
  }
}

.contentBlock {
  display: inline-flex;
  margin-left: 25px;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 150px);

  @media only screen and (max-width: $break_point_xs) {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }

  li {
    padding: 0 15px;

    &h6:first-child {
      font-weight: 400;
    }

    &h6:nth-child(2) {
      font-weight: 900;
    }
  }
}
