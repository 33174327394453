@import "../common/variables";

.userwrapper {
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
}

.truncate-wallet {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.countrywrapper {
  display: inline-flex;
  align-self: flex-end;

  img {
    @media only screen and (max-width: $break_point_lg) {
      width: 30px;
    }
  }
}

.img-wrap {
  position: relative;
  display: flex;
}

.user-img-block {
  max-width: 80px;
  height: 80px;
  img {
    border-radius: 50%;
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 60px;
    height: 60px;
  }

  @media only screen and (max-width: $break_point_xs) {
    width: 40px;
    height: 40px;
  }
}

.text-block {
  display: inline-flex;
  align-self: flex-start;
}

.id-text-block {
  display: inline-flex;
  align-items: center;
}
