@import "../common/variables";

.community-detail-block {
  background: $secondary;
  border-radius: 15px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;

  &.saffron {
    background-color: #fcdfb3;
  }

  &.green {
    background-color: #d4f3c9;
  }

  &.dark-green {
    background-color: #cff473;
  }

  &.yellow {
    background-color: #f5f38e;
  }
}

.header-details {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.first-b {
  display: inline-flex;
  align-items: center;
}

.common-block {
  display: inline-flex;
  align-items: center;
  margin: 0 30px;

  @media only screen and (max-width: $break_point_xs) {
    margin: 0 10px;
  }
}

.country-block {
  margin-right: 10px;

  img {
    width: 44px;
    height: 30px;
  }
}

.heart-image-block {
  width: 59px;
  height: 49px;
  margin: 0 30px;

  @media only screen and (max-width: $break_point_xs) {
    margin: 0 10px;
    width: 30px;
    height: 30px;
  }
}

.user-block {
  img {
    width: 90px;
    height: 90px;

    @media only screen and (max-width: $break_point_xs) {
      width: 45px;
      height: 45px;
    }
  }
}

.second-block {
  display: inline-flex;
  align-items: center;

  .coin {
    width: 45px;
    height: 45px;
    margin-left: 10px;
  }
}
