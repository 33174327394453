.forthWrapper {
  position: relative;

  .firstNest {
    position: absolute;
    top: 28%;
    left: 5%;
  }

  .secondNest {
    position: absolute;
    top: 35%;
    left: 23%;
  }

  .thirdNest {
    position: absolute;
    left: 0;
    right: 0;
    top: 4%;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }

  .forthNest {
    position: absolute;
    right: -1%;
    top: 33%;
  }

  // make eagles smaller in this nest
  .nest-wrapper {
    width: 120px;
  }

  @media (max-width: 992px) and (min-width: 900px) {
    .thirdNest {
      top: 9%;
      left: 2%;
    }

    .secondNest {
      top: 39%;
    }

    .firstNest {
      top: 35%;
    }

    .forthNest {
      top: 38%;
    }
  }

  @media (max-width: 767px) and (min-width: 600px) {
    .thirdNest {
      top: 9%;
      left: 13%;
    }

    .secondNest {
      top: 39%;
      left: 28%;
    }

    .firstNest {
      top: 35%;
      left: 9%;
    }

    .forthNest {
      top: 40%;
      right: 3%;
    }
  }

  @media (max-width: 600px) and (min-width: 500px) {
    .thirdNest {
      top: 7%;
      left: 15%;
    }

    .secondNest {
      top: 38%;
      left: 27%;
    }

    .firstNest {
      top: 32%;
      left: 9%;
    }

    .forthNest {
      top: 37%;
      right: 3%;
    }
  }

  @media (max-width: 500px) and (min-width: 400px) {
    .thirdNest {
      top: 7%;
      left: 16%;
    }

    .secondNest {
      top: 38%;
      left: 24%;
    }

    .firstNest {
      top: 32%;
      left: 6%;
    }

    .forthNest {
      top: 37%;
      right: 1%;
    }
  }

  // iphone se and 12 pro
  @media screen and (max-width: 394px) and (min-width: 360px) {
    .thirdNest {
      left: 21%;
      top: 2%;
    }

    .secondNest {
      left: 21%;
      top: 33%;
    }

    .firstNest {
      left: 3%;
    }
  }

  // iphone xr
  @media screen and (max-width: 417px) and (min-width: 412px) {
    .thirdNest {
      left: 21%;
    }
  }

  // ipad air
  @media screen and (max-width: 822px) and (min-width: 819px) {
    .thirdNest {
      top: 7%;
      left: 4%;
    }

    .firstNest {
      top: 32%;
      left: 1%;
    }

    .secondNest {
      top: 38%;
      left: 19%;
    }

    .forthNest {
      top: 37%;
      right: -4%;
    }
  }

  // ipad mini
  @media screen and (max-width: 770px) and (min-width: 768px) {
    .thirdNest {
      top: 5%;
      left: 4%;
    }

    .firstNest {
      top: 31%;
      left: 0%;
    }

    .secondNest {
      top: 36%;
      left: 17%;
    }

    .forthNest {
      top: 35%;
      right: -7%;
    }
  }
}
