@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import "./variables";
// @import "../../../..//node_modules/nes.css/css/nes.css";

@font-face {
  font-family: "PixeloidSans";
  src: url(../../fonts/PixeloidSans.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PixeloidSans";
  src: url(../../fonts/PixeloidSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "connectionii";
  src: url(../../fonts/connectionii.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-size: cover;
  background-position: center;
  font-family: $primary_font;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-weight: 400;
}

*,
::after,
::before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.ReactModalPortal > div {
  z-index: 999;
}

.third-font {
  font-family: $thirdfont;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.primary-font {
  font-family: $primary_font;
}

.secondary-font {
  font-family: $secondary_font;
}

.container {
  padding: 0 36px;
  max-width: 100%;

  @media only screen and (min-width: $break_point_xxl) {
    max-width: 1920px;
    margin: 0 auto;
  }

  @media only screen and (max-width: $break_point_lg) {
    padding: 0 16px;
  }
}

// fonts
h1,
.h1 {
  font-size: 48px;
  line-height: 62px;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 45px;
}

h3,
.h3 {
  font-size: 30px;
  line-height: 40px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1px;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 24px;
}

p,
body {
  font-size: 14px;
  line-height: 20px;
}

.small {
  font-size: 12px;
  line-height: 18px;
}

// font weight css
.f-700 {
  font-weight: 700;
}

.f-400 {
  font-weight: 400;
}

// btn css started
.custom-btn {
  padding: 15px;

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.custom-button {
  font-weight: bold;

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

// color css started
.tx-black {
  color: #000000;
}

.tx-twelve {
  color: $twelve;
}

.tx-white {
  color: #ffffff;
}

.tx-primary {
  color: $primary;
}

.tx-secondary {
  color: $secondary;
}

.tx-third {
  color: $teritory;
}

.tx-forth {
  color: $forth;
}

.tx-fifth {
  color: $fifth;
}

.tx-sixth {
  color: $sixth;
}

.tx-tenth {
  color: $tenth;
}

.tx-eleven {
  color: $eleven;
}

.bg-pink {
  background-color: #fcdfb3;
}

.bg-yellow {
  background-color: #f5f38e;
}

.bg-green {
  background-color: #cff473;
}

.bg-light-blue {
  background-color: #d4f3c9;
}

.bg-black {
  background-color: #000000;
}

.bg-twelve {
  background-color: $twelve;
}

.bg-white {
  background-color: #ffffff;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-third {
  background-color: $teritory;
}

.bg-forth {
  background-color: $forth;
}

.bg-fifth {
  background-color: $fifth;
}

// alignment class

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-item-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 15px;
}

.gap-3 {
  gap: 20px;
}

/*margin class*/
.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.ms-1 {
  margin-left: 8px;
}

.me-1 {
  margin-right: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.ms-2 {
  margin-left: 16px;
}

.me-2 {
  margin-right: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.ms-3 {
  margin-left: 24px;
}

.me-3 {
  margin-right: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mb-4 {
  margin-bottom: 32px;
}

.ms-4 {
  margin-left: 32px;
}

.me-4 {
  margin-right: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 40px;
}

.ms-5 {
  margin-left: 40px;
}

.me-5 {
  margin-right: 40px;
}

.custom-btn {
  font-size: 20px;
  line-height: 28px;
  font-family: $primary_font;
  font-weight: 700;
  display: flex;
  padding: 10px 20px;
  background-color: $primary;
  width: fit-content;

  @media only screen and (max-width: $break_point_lg) {
    font-size: 14px;
    line-height: 18px;
    padding: 4px 8px;
  }
}

@media only screen and (max-width: $break_point_xxl) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 42px;
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 35px;
  }

  h3,
  .h3 {
    font-size: 22px;
    line-height: 32px;
  }

  h4,
  .h4 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
  }

  h5,
  .h5 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 14px;
    line-height: 22px;
  }

  p,
  body {
    font-size: 13px;
    line-height: 20px;
  }
}

@media only screen and (max-width: $break_point_xl) {
  h1,
  .h1 {
    font-size: 26px;
    line-height: 36px;
  }

  h2,
  .h2 {
    font-size: 22px;
    line-height: 32px;
  }

  h3,
  .h3 {
    font-size: 20px;
    line-height: 28px;
  }

  h4,
  .h4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  h5,
  .h5 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 14px;
    line-height: 22px;
  }

  p,
  body {
    font-size: 13px;
    line-height: 20px;
  }
}

@media only screen and (max-width: $break_point_lg) {
  h1,
  .h1 {
    font-size: 24px;
    line-height: 34px;
  }

  h2,
  .h2 {
    font-size: 20px;
    line-height: 28px;
  }

  h3,
  .h3 {
    font-size: 18px;
    line-height: 26px;
  }

  h4,
  .h4 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
  }

  h5,
  .h5 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 12px;
    line-height: 18px;
  }

  p,
  body {
    font-size: 12px;
    line-height: 18px;
  }
}

@media only screen and (max-width: $break_point_sm) {
  h1,
  .h1 {
    font-size: 20px;
    line-height: 28px;
  }

  h2,
  .h2 {
    font-size: 18px;
    line-height: 26px;
  }

  h3,
  .h3 {
    font-size: 16px;
    line-height: 24px;
  }

  h4,
  .h4 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
  }

  h5,
  .h5 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  h6,
  .h6 {
    font-size: 12px;
    line-height: 18px;
  }

  p,
  body {
    font-size: 12px;
    line-height: 18px;
  }
}

.video-wrapper {
  width: calc(100% - 180px);
  margin: 0 auto;
  height: 80vh;
  margin-top: 2vh;
  position: relative;

  .key-press {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;

    @media only screen and (max-width: $break_point_md) {
      position: inherit;
    }
  }

  @media only screen and (max-width: $break_point_lg) {
    width: calc(100% - 30px);
    height: auto;
  }
}

.common-lap {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: auto;

  @media only screen and (max-width: $break_point_lg) {
    height: calc(100vh - 75px);
  }

  @media only screen and (max-width: $break_point_sm) {
    height: calc(100vh - 67px);
  }
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.profile {
  max-width: 100px;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
