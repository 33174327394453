@import "../assets/scss/common/variables";

.wrapper {
  width: 100%;
  position: relative;
  height: 120px;

  .contentBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  img {
    height: 120px;
    width: 100%;

    @media only screen and (max-width: $break_point_xxl) {
      height: 80px;
    }
  }

  @media only screen and (max-width: $break_point_xxl) {
    height: 80px;
  }
}
