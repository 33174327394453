@import "../common/variables";

.custom-home-wrapper {
  position: relative;
  width: calc(100% - 500px);
  display: inline-flex;
  max-height: 730px;
  flex-direction: column;

  @media only screen and (max-width: $break_point_xxl) {
    width: calc(100% - 400px);
  }

  @media only screen and (max-width: $break_point_md) {
    width: calc(100% - 270px);
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 100%;
  }
}

.main-wrapper-1 {
  // max-height: 730px;
  margin-top: 30px;
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;

  // if wrapper is not active then
  &:not(.active) {
    h3 {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > img {
      filter: blur(6px);
    }

    > div {
      display: none;
    }
  }
}

.main-component {
  align-content: flex-start;
  display: flex;
  width: 100%;

  @media only screen and (max-width: $break_point_sm) {
    display: block;
  }
}

.right-sidebar {
  width: 500px;
  display: inline-flex;
  padding-left: 68px;
  flex-direction: column;

  @media only screen and (max-width: $break_point_xxl) {
    width: 400px;
  }

  @media only screen and (max-width: $break_point_md) {
    width: 250px;
    margin-left: 20px;
    padding-left: 0px;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
  }
}

.main-bg {
  height: 100%;
  width: 100%;
}

.bird-nest {
  position: absolute;
  top: 47%;
  display: flex;
  right: 29%;

  @media only screen and (max-width: 1750px) {
    top: 44.5%;
  }

  @media only screen and (max-width: $break_point_xxl) {
    top: 41.5%;
    right: 25%;
  }

  @media only screen and (max-width: $break_point_xl) {
    top: 48.5%;
    right: 28%;
  }

  @media only screen and (max-width: $break_point_lg) {
    top: 45.5%;
    right: 26%;
  }

  @media only screen and (max-width: $break_point_sm) {
    top: 38%;
  }

  @media only screen and (max-width: $break_point_md) {
    right: 22%;
  }

  @media only screen and (max-width: $break_point_sm) {
    right: 32%;
    top: 52%;
  }

  @media only screen and (max-width: $break_point_xs) {
    top: 40%;
    right: 26%;
  }

  @media only screen and (max-width: 374px) {
    top: 44%;
    right: 25%;
  }
}

.eag-block {
  position: absolute;
  top: 50%;
  display: flex;
  right: 34%;
  position: absolute;

  @media only screen and (max-width: $break_point_xxl) {
    width: 100px;
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 66px;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 50px;
    top: 40%;
  }
}

.bird-2 {
  position: absolute;
  top: 45%;
  display: flex;
  right: 30%;

  @media only screen and (max-width: $break_point_xxl) {
    width: 100px;
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 66px;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 50px;
    top: 40%;
  }
}

.name-input {
  bottom: -42px;
  display: flex;
  right: 150px;
  position: absolute;
  left: -200px;

  @media only screen and (max-width: $break_point_xxl) {
    right: 100px;
    bottom: -50px;
  }

  @media only screen and (max-width: $break_point_sm) {
    bottom: -45px;
    left: -85px;
  }

  input {
    background-color: transparent;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 700;
    font-family: $primary_font;
    position: relative;
    width: 150px;

    @media only screen and (max-width: $break_point_sm) {
      font-size: 11px;
      padding: 0px 5px;
      width: 80px;
    }
  }

  .cls-input {
    position: relative;

    &:after {
      content: "/";
      position: absolute;
      top: -15px;
      right: -48px;
      width: 50px;
      height: 50px;
      display: block;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.bottom-content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: $break_point_lg) {
    margin-top: auto;
    flex-direction: row-reverse;
  }
}

.btn-c-wrapper {
  display: flex;
  justify-content: flex-end;
}

.custom-btn {
  margin-top: 15px;
}

.arrow-block {
  display: flex;
  align-items: center;
  margin: 15px auto;

  h2 {
    margin: 0 50px;

    @media only screen and (max-width: $break_point_lg) {
      width: 0 15px;
    }
  }

  img {
    width: 25px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 15px;
    }

    @media only screen and (max-width: $break_point_lg) {
      width: 10px;
    }
  }
}

.stack-wrap {
  img {
    @media only screen and (max-width: $break_point_lg) {
      width: 40px;
    }
  }
}

.forth-wrapper {
  .bird-nest {
    position: absolute;
    top: 21%;
    display: flex;
    right: 45%;

    @media only screen and (max-width: $break_point_lg) {
      top: 24%;
    }

    @media only screen and (max-width: $break_point_sm) {
      top: 14%;
    }
  }

  .bird-2 {
    position: absolute;
    top: 22%;
    display: flex;
    right: 28%;
  }

  .eag-block {
    top: 26%;
    display: flex;
    right: 8%;
    position: absolute;
  }

  .name-input {
    &.second {
      bottom: -85px;
      right: -71%;
      left: 115px;

      @media only screen and (max-width: $break_point_xxl) {
        bottom: -45px;
        right: -100%;
        left: 78px;
      }

      @media only screen and (max-width: $break_point_lg) {
        bottom: -80px;
        right: -101%;
        left: 14px;
      }
    }

    &.first {
      bottom: -89px;

      @media only screen and (max-width: $break_point_xxl) {
        bottom: -35px;
      }

      @media only screen and (max-width: $break_point_sm) {
        right: 67px;
        left: -107px;
      }

      @media only screen and (max-width: $break_point_sm) {
        bottom: -76px;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border: 4px inset transparent;
  border-bottom-color: #9a5930;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 5px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 0;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.active {
  border-bottom-color: transparent;
  border-color: #9a5930;
}

.in-active {
  pointer-events: none;
  opacity: 0.6;
}
