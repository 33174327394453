:root {
  --primary: #c72f31;
  --secondary: #c4c4c4;
  --third: #e4e3e3;
  --forth: #30b700;
  --fifth: rgba(0, 0, 0, 0.7);
  --six: #2d356f;
  --seven: #545454;
  --eight: #d9d9d9;
  --ninth: #cb9072;
  --tenth: #f8cca0;
  --eleven: #fce57b;
  --twelve: #200505;
}

$primary_font: "PixeloidSans";
$secondary_font: "connectionii";
$thirdfont: "Poppins", sans-serif;

$primary: var(--primary);
$secondary: var(--secondary);
$teritory: var(--third);
$forth: var(--forth);
$fifth: var(--fifth);
$sixth: var(--six);
$seven: var(--seven);
$eight: var(--eight);
$ninth: var(--ninth);
$tenth: var(--tenth);
$eleven: var(--eleven);
$twelve: var(--twelve);
$colorSuccess: #28a745;
$break_point_xxl: "1550px";
$break_point_xl: "1399px";
$break_point_lg: "1199px";
$break_point_md: "991px";
$break_point_sm: "767px";
$break_point_xs: "574px";
$break_point_xss: "574px";
