@import "../common/variables";
@import "../common/mixins";

.header-wrapper {
  display: flex;
  align-items: center;
  padding: 32px 0;

  @media only screen and (max-width: $break_point_lg) {
    padding: 15px 0;
  }
}

.logo {
  width: 145px;
  height: 105px;

  @media only screen and (max-width: $break_point_lg) {
    width: 54px;
    height: 39px;
  }
}

.page-link {
  margin-left: 100px;
  display: flex;
  width: calc(100% - 250px);

  a {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-decoration: none;
    color: $sixth;
    text-transform: capitalize;

    @media only screen and (max-width: $break_point_xxl) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .link-wrapper {
    margin-left: 60px;

    @media only screen and (max-width: $break_point_sm) {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 100%;
  }
}

.night-theme .page-link {
  a {
    color: white;
  }
}

.clear-notification {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;

  .l-content {
    margin-left: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-decoration: underline;
  }
}

.night-theme .clear-notification .l-content {
  color: white;
}

.right-link-wrapper {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}

.start-link {
  display: inline-flex;
  align-items: center;
}

.coin-box {
  background-image: url("../../images/coin-count.png");
  display: inline-flex;
  background-size: 172px;
  height: 68px;
  width: 172px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;

  @media only screen and (max-width: $break_point_xxl) {
    font-size: 20px;
    line-height: 20px;
    width: 120px;
    background-size: 120px;
    height: 53px;
  }

  .coin-img {
    width: 45px;
    height: 45px;

    @media only screen and (max-width: $break_point_xxl) {
      width: 25px;
      height: 25px;
    }

    @media only screen and (max-width: $break_point_lg) {
      width: 20px;
      height: 20px;
    }
  }

  @media only screen and (max-width: $break_point_lg) {
    height: 39px;
    width: 100px;
    background-size: 100px;
    font-size: 16px;
    line-height: 16px;
  }
}

.notification-button {
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  position: relative;

  .round-wrap {
    border: 2px solid #ffffff;
    background-color: $primary;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    position: absolute;
    bottom: -25px;
    left: -15px;
  }
}

.notification {
  position: relative;

  &.open {
    .notification-menu {
      display: block;
      z-index: 1;
    }
  }
}

.notification-menu {
  position: absolute;
  width: 420px;
  display: none;
  right: 0;

  .notification-container {
    max-height: 600px;
    overflow-y: auto;
    @include customScrollBar;
  }

  li {
    border: 3px solid #000000;
    border-radius: 50px 0 50px 0;
    padding: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    margin-top: 15px;
    background-color: $eight;

    &.special {
      background-color: $seven;
      color: #ffffff;
      font-weight: 700;
      padding: 20px;
      border-radius: 100px 0 100px 0;

      &.nm-bg {
        background-color: $eight;
        color: #000000;
        font-weight: 400;
      }
    }

    &:after {
      width: 16px;
      height: 32px;
      content: "";
      background-color: $seven;
      position: absolute;
      bottom: -28px;
      display: block;
      left: 50px;
      border: 5px solid #000000;
      z-index: 1;
    }

    &:before {
      width: 16px;
      height: 32px;
      content: "";
      background-color: $seven;
      position: absolute;
      bottom: -28px;
      display: block;
      right: 50px;
      border: 5px solid #000000;
      z-index: 1;
    }

    &:last-child {
      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }
  }
}

.userlink {
  background-color: transparent;
  border: 0;
  outline: none;

  img {
    @media only screen and (max-width: $break_point_xxl) {
      width: 70px;
      height: 70px;
    }
  }
}

.burger-btn {
  background-color: transparent;
  border: 0;
  outline: none;
  display: none;

  @media only screen and (max-width: $break_point_lg) {
    display: inline-flex;
    margin-right: 15px;
  }
}

.mobile-hide {
  @media only screen and (max-width: $break_point_lg) {
    display: none;
  }
}

.backdrop {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100%;
  content: " ";
  background-color: rgba(0, 0, 0, 0.5);
  left: -100%;
  transition: all ease 0.1s;
  top: 0;
  z-index: 9;

  &.open {
    left: 0;
  }
}

.responsive-header {
  position: fixed;
  overflow-y: auto;
  width: 285px;
  background-color: $ninth;
  height: 100vh;
  top: 0;
  left: -300px;
  transition: all ease 0.2s;
  padding: 15px;
  z-index: 99;

  &.open {
    left: 0px;
  }

  .user-block {
    padding: 13px;
    display: flex;

    .user-first {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-content-block {
      max-width: calc(100% - 70px);
      margin-left: 20px;
    }
  }
}

.divider {
  margin: 15px 0;
  display: block;
  content: " ";
  background-color: #ffffff;
  height: 1px;
  width: 100%;
}

.page-links {
  list-style-type: none;

  li {
    margin-bottom: 15px;

    .r-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: $primary_font;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: $sixth;

      .icon-wr {
        width: 50px;
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;

        img {
          width: 26px;
          height: 24px;
        }

        .notification-button {
          .round-wrap {
            bottom: -7px;
            left: -7px;
            height: 14px;
            width: 14px;
            font-size: 5px;
            line-height: 10px;
          }
        }
      }

      .content-wrap-box {
        width: calc(100% - 50px);
      }
    }
  }
}

.userDropdownMenu {
  background: linear-gradient(180deg, #ffcead 19.76%, #ffe2b6 84.43%);
  border-radius: 4px;
  // width: max-content;
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 0 25px;
  display: none;

  &.open {
    display: block;
  }
}

.menu-op {
  .item {
    padding: 15px;
    display: block;
    border-bottom: 1px solid #c4c4c4;

    &:hover,
    &.active,
    &:focus {
      color: $primary;
    }
  }

  li:last-child {
    .item {
      border-bottom-color: transparent;
    }
  }
}
