@import "../../assets/scss/common/variables";

.communityBlock {
  background: $secondary;
  border-radius: 15px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;

  &.saffron {
    background-color: #fcdfb3;
  }

  &.green {
    background-color: #d4f3c9;
  }

  &.dark-green {
    background-color: #cff473;
  }

  &.yellow {
    background-color: #f5f38e;
  }

  .heartBlock {
    padding: 0 50px;
    display: inline-flex;
    align-items: center;

    img {
      width: 60px;

      @media only screen and (max-width: $break_point_xxl) {
        width: 45px;
      }

      @media only screen and (max-width: $break_point_lg) {
        width: 30px;
      }
    }

    @media only screen and (max-width: $break_point_xxl) {
      padding: 0 15px;
    }
  }

  .tokenBlock {
    display: inline-flex;
    align-items: center;
  }

  .firstBlockWrapper {
    display: inline-flex;

    .firstTextBlock {
      display: flex;
      flex-direction: column;
    }

    .birdWrapperBlock {
      width: 200px;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      min-height: 120px;

      img {
        width: 90px;

        @media only screen and (max-width: $break_point_xxl) {
          width: 90px;
        }

        @media only screen and (max-width: $break_point_lg) {
          width: 65px;
        }

        @media only screen and (max-width: $break_point_md) {
          width: 55px;
        }

        @media only screen and (max-width: $break_point_xs) {
          width: 45px;
        }
      }

      @media only screen and (max-width: $break_point_xxl) {
        min-height: 120px;
        width: 190px;
      }

      @media only screen and (max-width: $break_point_lg) {
        min-height: 110px;
        width: 160px;
      }

      @media only screen and (max-width: $break_point_md) {
        min-height: 90px;
        width: 135px;
      }

      @media only screen and (max-width: $break_point_xs) {
        min-height: 80px;
        width: 120px;
      }
    }

    @media only screen and (max-width: $break_point_md) {
      align-items: center;
    }
  }
}
