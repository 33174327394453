@import "../common/variables";

.counter-board {
  position: relative;
  width: 420px;
  height: 165px;

  @media only screen and (max-width: $break_point_xxl) {
    width: 350px;
    height: 138px;
  }

  @media only screen and (max-width: $break_point_md) {
    width: 250px;
    height: 138px;
  }

  .left,
  .right {
    position: absolute;
    left: 10px;
    right: auto;
    z-index: 20;
    top: 20%;
    cursor: pointer;

    &.right {
      right: 8px;
      left: auto;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.counter-img {
  width: 420px;
  clip: rect(0px, 412px, 420px, 0px);
  position: absolute;

  @media only screen and (max-width: $break_point_xxl) {
    width: 350px;
    clip: rect(0px, 344px, 138px, 0px);
  }

  @media only screen and (max-width: $break_point_md) {
    width: 250px;
    clip: rect(0px, 244px, 138px, 0px);
  }
}

.counter-content {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 15px 35px;
  width: 100%;

  @media only screen and (max-width: $break_point_md) {
    top: 0px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
}

.counter-imer-block {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.i1-block {
  min-width: 45px;

  @media only screen and (max-width: $break_point_xxl) {
    min-width: 30px;
    width: 30px;
  }

  @media only screen and (max-width: $break_point_md) {
    min-width: 25px;
    width: 25px;
  }
}

.counter-time {
  margin-left: 15px;

  ul {
    display: flex;

    li {
      display: inline-flex;
      flex-direction: column;
      padding: 0 5px;
      // justify-content: center;
      text-align: center;

      @media only screen and (max-width: $break_point_md) {
        padding: 0 2px;
      }

      .t-title {
        font-size: 30px;
        line-height: 42px;
        font-weight: 700;

        @media only screen and (max-width: $break_point_xxl) {
          font-size: 20px;
          line-height: 28px;
        }

        @media only screen and (max-width: $break_point_md) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .day-tl {
        font-size: 24px;
        line-height: 30px;

        @media only screen and (max-width: $break_point_xxl) {
          font-size: 14px;
          line-height: 25px;
        }

        @media only screen and (max-width: $break_point_md) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .tk {
        margin: 0 2px;
        display: inline-flex;
        align-items: flex-start;
      }
    }
  }
}
