@import "../common/variables";

.sidebar-wrapper {
  display: block;
  width: 240px;
  padding-top: 140px;

  @media only screen and (max-width: $break_point_lg) {
    display: none;
  }
}

.page-sidebar-link {
  position: relative;
}

.active-icon {
  position: absolute;
  top: -5px;
  display: none;
}

.page-link-content {
  font-family: $primary_font;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: $sixth;
}
.night-theme .page-link-content {
  color: white;
}
.night-theme .sidebar-link-list {
  a {
    &:hover {
      text-decoration-color: white;
    }
  }
}

.sidebar-link-list a {
  text-decoration: none;
  display: flex;
  margin-bottom: 82px;

  &.active {
    .page-link-content {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.02em;
    }

    .active-icon {
      display: block;
    }
  }
}
