.thirdWrapper {
  position: relative;

  .birdNest {
    position: absolute;
    top: 25%;
    display: flex;

    .firstNest {
      margin-left: 48%;
      margin-top: 3%;
      z-index: 1;
    }

    .secondNest {
      margin-left: -2%;
      margin-top: 4%;
      z-index: 2;
    }

    .thirdNest {
      margin-left: -8%;
      margin-top: -5%;
      z-index: 0;
    }

    @media screen and (max-width: 1916px) {
      .firstNest {
        margin-left: 45%;
      }
    }

    @media screen and (max-width: 1892px) {
      .firstNest {
        margin-left: 40%;
      }
    }

    @media screen and (max-width: 1820px) {
      .firstNest {
        margin-left: 35%;
      }
    }

    @media screen and (max-width: 1780px) {
      .firstNest {
        margin-left: 38%;
        margin-right: -5%;
      }
    }

    @media screen and (max-width: 1656px) {
      .firstNest {
        margin-left: 35%;
        margin-right: -7%;
      }
    }

    @media screen and (max-width: 1623px) {
      .thirdNest {
        margin-left: -15%;
      }
    }

    @media screen and (max-width: 1580px) {
      .firstNest {
        margin-left: 30%;
        margin-top: 0%;
      }
    }

    @media screen and (max-width: 1550px) {
      .firstNest {
        margin-left: 42%;
      }

      .thirdNest {
        margin-left: -8%;
      }
    }

    @media screen and (max-width: 1519px) {
      .firstNest {
        margin-left: 35%;
      }
    }

    @media screen and (max-width: 1457px) {
      .thirdNest {
        margin-left: -12%;
      }
    }

    @media screen and (max-width: 1439px) {
      .firstNest {
        margin-left: 32%;
      }
    }

    @media screen and (max-width: 1400px) {
      .firstNest {
        margin-left: 45%;
      }

      .secondNest {
        margin-left: 0;
      }

      .thirdNest {
        margin-left: -5%;
      }
    }

    @media screen and (max-width: 1358px) {
      .firstNest {
        margin-left: 40%;
      }
    }

    @media screen and (max-width: 1320px) {
      .thirdNest {
        margin-left: -12%;
      }
    }

    @media screen and (max-width: 1291px) {
      .firstNest {
        margin-left: 35%;
      }
    }

    @media screen and (max-width: 1263px) {
      .firstNest {
        margin-left: 32%;
      }
    }

    @media screen and (max-width: 1242px) {
      .thirdNest {
        margin-left: -15%;
      }
    }

    @media screen and (max-width: 1214px) {
      .firstNest {
        margin-left: 30%;
      }

      .secondNest {
        margin-left: -4%;
      }
    }

    @media screen and (max-width: 1200px) {
      top: 30%;

      .firstNest {
        margin-left: 45%;
      }

      .secondNest {
        margin-left: 3%;
      }

      .thirdNest {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 1162px) {
      .thirdNest {
        margin-left: -5%;
      }
    }

    @media screen and (max-width: 1100px) {
      .firstNest {
        margin-left: 40%;
      }

      .thirdNest {
        margin-left: -12%;
      }
    }

    @media screen and (max-width: 1068px) {
      top: 25%;

      .firstNest {
        margin-left: 35%;
      }
    }

    @media screen and (max-width: 1044px) {
      .firstNest {
        margin-left: 30%;
      }
    }

    @media screen and (max-width: 1000px) {
      .secondNest {
        margin-left: -3%;
      }
    }

    @media screen and (max-width: 992px) {
      top: 30%;

      .firstNest {
        margin-left: 45%;
      }
    }

    @media screen and (max-width: 955px) {
      .firstNest {
        margin-left: 40%;
      }

      .secondNest {
        margin-left: -3%;
      }

      .thirdNest {
        margin-left: -10%;
      }
    }
    @media screen and (max-width: 900px) {
      .firstNest {
        margin-left: 30%;
      }
    }
    @media screen and (max-width: 835px) {
      .thirdNest {
        margin-left: -15%;
      }
    }
    @media screen and (max-width: 800px) {
      .firstNest {
        margin-left: 23%;
      }
    }
    @media screen and (max-width: 768px) and (min-width: 768px) {
      top: 28%;
      .firstNest {
        margin-left: 20%;
      }
    }
    @media screen and (max-width: 767px) {
      .firstNest {
        margin-left: 90%;
      }
      .secondNest {
        margin-left: 20%;
      }
      .thirdNest {
        margin-left: 10%;
      }
    }
    @media screen and (max-width: 700px) {
      .firstNest {
        margin-left: 75%;
      }
    }
    @media screen and (max-width: 650px) {
      .secondNest {
        margin-left: 10%;
      }
    }
    @media screen and (max-width: 625px) {
      .thirdNest {
        margin-left: 0%;
      }
    }
    @media screen and (max-width: 600px) {
      .firstNest {
        margin-left: 65%;
      }
    }
    @media screen and (max-width: 550px) {
      .firstNest {
        margin-left: 50%;
      }
    }
    @media screen and (max-width: 500px) {
      .secondNest {
        margin-left: 0%;
      }
    }
    @media screen and (max-width: 460px) {
      .firstNest {
        margin-left: 40%;
      }
      .thirdNest {
        margin-left: -8%;
      }
    }
    @media screen and (max-width: 400px) {
      .firstNest {
        margin-left: 30%;
      }
    }
    @media screen and (max-width: 377px) {
      top: 25%;
      .thirdNest {
        margin-left: -12%;
      }
    }
    @media screen and (max-width: 355px) {
      .secondNest {
        margin-left: -8%;
      }
    }
    @media screen and (max-width: 340px) {
      .thirdNest {
        margin-left: -15%;
      }
    }
    @media screen and (max-width: 330px) {
      .firstNest {
        margin-left: 25%;
      }
    }
    @media screen and (max-width: 300px) {
      .firstNest {
        margin-left: 20%;
      }
    }
  }
}
