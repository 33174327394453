.main-block.credits {
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
  .profile-image {
    width: 20%;
    max-width: 150px;
    img {
      border-radius: 50%;
      box-shadow: 0px 0px 1px 4px white;
    }
  }
  .details {
    width: 70%;
    .name,
    .desc {
      color: black;
      font-size: 24px;
      font-weight: 600;
      word-break: break-all;
      line-height: 1.3;
    }
    .desc {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .logos {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-end;

    gap: 10px;
  }

  @media screen and (max-width: 719px) {
    justify-content: center;
    .logos {
      width: 100%;
      justify-content: center;
    }
    .profile-image {
      width: 100%;
    }
    .details {
      width: 100%;
      p {
        text-align: center;
      }
    }
  }
}
