.notificationItem {
  background: rgba(252, 223, 179, 0.8);
  border-radius: 15px;
  padding: 30px 20px;
  margin: 20px;
  font-size: 2vw;
  font-size: min(max(16px, 4vw), 22px);
  line-height: 1.6;
  span {
    font-weight: bold;
  }
  
  &:first-child {
    text-align: center;
    background: none;
    margin-top: -24px;
    padding-top: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}