.timeMachineLifes {
  height: fit-content;
  display: flex;
  margin-top: -4px;

  b {
    margin-top: 10px;
    font-size: 24px;
  }

  @media screen and (max-width: 1552px) {
    i {
      transform: scale(2) !important;
    }
  }
  @media screen and (max-width: 767px) {
    b {
      font-size: 20px;
    }
  }
}
