@import "../../assets/scss/common/variables";

.tabWrapper {
  .mainLinkWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $break_point_sm) {
      flex-wrap: wrap;
      gap: 15px;
    }
  }
  .tablinkWrapper {
    display: inline-flex;

    .tabLink {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: $sixth;
      position: relative;
      padding: 15px;
      margin-right: 4px;
      cursor: pointer;

      @media only screen and (max-width: $break_point_sm) {
        font-size: 14px;
      }
      @media only screen and (max-width: $break_point_xss) {
        font-size: 10px;
      }

      &:after {
        position: absolute;
        height: 80%;
        top: 50%;
        transform: translate(0, -50%);
        width: 4px;
        background-color: $sixth;
        right: -4px;
        display: block;
        content: " ";
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .borderBlock {
        position: absolute;
        bottom: 5px;
        text-align: center;
      }
    }
  }

  .tabContent {
    padding-top: 15px;
  }
}
