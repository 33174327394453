@import "../assets/scss/common/variables";

.wrapper {
  position: relative;

  .birdNest {
    position: absolute;
    left: 46%;
    top: 15%;

    .secondNest {
      margin-left: 55%;
    }

    @media screen and (max-width: 1100px) and (min-width: 1000px) {
      top: 17%;
      left: 44%;

      .secondNest {
        margin-left: 50%;
      }
    }

    @media screen and (max-width: 991px) and (min-width: 900px) {
      top: 25%;

      .secondNest {
        margin-top: 13%;
        margin-left: 40%;
      }
    }

    @media screen and (max-width: 900px) {
      top: 22%;
      left: 43%;
    }

    @media screen and (max-width: 768px) and (min-width: 655px) {
      top: 25%;
      left: 50%;

      .secondNest {
        margin-top: 35%;
        margin-left: 86%;
      }
    }

    @media screen and (max-width: 655px) and (min-width: 599px) {
      top: 25%;
      left: 50%;

      .secondNest {
        margin-top: 15%;
        margin-left: 86%;
      }
    }

    @media screen and (max-width: 599px) and (min-width: 513px) {
      top: 25%;
      left: 50%;

      .secondNest {
        margin-top: 15%;
        margin-left: 60%;
      }
    }

    @media screen and (max-width: 513px) {
      .secondNest {
        margin-left: 60%;
      }
    }

    // iphone se and 12 pro
    @media screen and (max-width: 394px) and (min-width: 360px) {
      top: 13%;
      left: 42%;

      .secondNest {
        margin-left: 50%;
      }
    }

    // iphone xr
    @media screen and (max-width: 417px) and (min-width: 412px) {
      top: 17%;
      left: 45%;

      .secondNest {
        margin-left: 43%;
      }
    }

    // ipad air
    @media screen and (max-width: 822px) and (min-width: 819px) {
      top: 22%;
      left: 40%;

      .secondNest {
        margin-left: 43%;
      }
    }

    // ipad mini
    @media screen and (max-width: 770px) and (min-width: 768px) {
      top: 20%;
      left: 40%;

      .secondNest {
        margin-top: 0%;
        margin-left: 35%;
      }
    }
  }
}
