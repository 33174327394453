.bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 25px 0;
}

.volum-button {
  svg {
    width: 50px;
  }
}
