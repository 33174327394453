.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .textBlock {
    display: inline-flex;
    max-width: 60%;
    align-items: center;

    img {
      width: 68px;
      height: 68px;
      margin-right: 15px;
    }

    h3 {
      line-height: 25px;
      margin-bottom: 0;
      color: var(--twelve);
    }
  }
}
