@import "../common/variables";

.home-wrapper {
  max-width: calc(100% - 200px);
  margin: 180px auto;

  @media only screen and (max-width: $break_point_lg) {
    max-width: calc(100% - 100px);
    margin: 90px auto;
  }

  @media only screen and (max-width: $break_point_sm) {
    max-width: calc(100% - 40px);
    margin: 45px auto;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;

  @media only screen and (max-width: $break_point_sm) {
    display: block;
  }
}

.left-portion {
  display: inline-flex;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $break_point_sm) {
    width: 100%;
  }
}

.right-portion {
  display: inline-flex;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: $break_point_sm) {
    width: 100%;
  }
}

.button-wrapper {
  position: absolute;
  top: 50%;
  left: calc(50% - 15px);
  transform: translate(-50%, -50%);
}

.logo-img {
  max-width: 425px;

  @media only screen and (max-width: $break_point_xxl) {
    max-width: 250px;
  }

  @media only screen and (max-width: $break_point_sm) {
    max-width: 250px;
  }
}

.qr-img {
  width: 600px;

  @media only screen and (max-width: $break_point_xxl) {
    width: 450px;
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 350px;
  }

  @media only screen and (max-width: $break_point_md) {
    width: 300px;
  }

  @media only screen and (max-width: $break_point_sm) {
    width: 270px;
  }
}

.title {
  margin-top: 50px;
  letter-spacing: 5px;
  background: linear-gradient(180deg, #c72f31 22.39%, #2d356f 79.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;

  @media only screen and (max-width: $break_point_md) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.qr-wrapper {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  left: calc(50% - 15px);
  transform: translate(-50%, -50%);
  padding: 45px;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: $break_point_md) {
    padding: 45px;
    left: calc(50% - 8px);
  }
}

.position-relative {
  position: relative;
}

.about-us-wrapper {
  width: 70%;
  position: relative;
  content: "";
  margin: 90px auto;
  background-color: $teritory;
  border-top: 5px solid;
  border-bottom: 5px solid;
  max-width: 1390px;

  @media only screen and (max-width: $break_point_xl) {
    width: 90%;
  }

  @media only screen and (max-width: $break_point_lg) {
    width: 100%;
  }
}

.content {
  border-top: 5px solid $forth;
  padding: 40px;
  border-bottom: 5px solid $forth;

  @media only screen and (max-width: $break_point_sm) {
    padding: 20px;
  }
}

.left-ear {
  position: absolute;
  top: 0%;
  left: -15px;
}

.right-ear {
  position: absolute;
  top: 0%;
  right: -15px;
}

.content-portion {
  display: flex;
  padding: 50px 0;

  @media only screen and (max-width: $break_point_sm) {
    display: block;
    padding: 10px 0;
  }

  .left-img {
    width: 373px;
    height: 373px;

    img {
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: $break_point_xxl) {
      width: 250px;
      height: 250px;
    }

    @media only screen and (max-width: $break_point_xl) {
      width: 200px;
      height: 200px;
    }

    @media only screen and (max-width: $break_point_sm) {
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
  }

  .right-content {
    width: calc(100% - 375px);

    @media only screen and (max-width: $break_point_xxl) {
      width: calc(100% - 250px);
    }

    @media only screen and (max-width: $break_point_xl) {
      width: calc(100% - 200px);
    }

    @media only screen and (max-width: $break_point_sm) {
      width: 100%;
    }
  }
}

.from-tl {
  text-align: right;
}

.bottom-ear {
  position: absolute;
  bottom: -23px;
  left: 50%;
  transform: translate(-50%, 0);
}
