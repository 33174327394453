@import "../../assets/scss/common/variables";

.historyWrapper {
  padding: 30px 70px;

  @media only screen and (max-width: $break_point_xxl) {
    padding: 30px 30px;
  }

  @media only screen and (max-width: $break_point_xl) {
    padding: 30px 20px;
  }

  @media only screen and (max-width: $break_point_md) {
    padding: 30px 15px;
  }
}
