.searchInput {
  position: relative;

  input {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 50px;
    margin-bottom: 0;

    &:focus {
      outline: 0;
    }
  }

  .searchicon {
    position: absolute;
    left: 10px;
    background-color: transparent;
    outline: 0;
    border: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
