@import "../common/variables";

.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 990;
  top: 0;
  left: 0;
  display: flex;
  content: " ";
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 400px;

  @media only screen and (max-width: $break_point_xs) {
    max-width: 320px;
  }
}

.normal-modal-content {
  position: fixed;
  width: 400px;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: $break_point_xs) {
    width: 100%;
    max-width: 320px;
  }
}

.main-wrap {
  position: relative;
}

.left-wrapper {
  position: absolute;
  top: 35%;
  width: 150px;
  left: 5.5%;
  text-align: center;
  transform: translate(0, -50%);

  @media only screen and (max-width: $break_point_xs) {
    width: 120px;
    top: 40%;
    left: 6.5%;
  }
}

.right-wrapper {
  position: absolute;
  top: 35%;
  width: 150px;
  right: 13%;
  text-align: center;
  transform: translate(0, -50%);

  .innerbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
  }

  button {
    width: fit-content;
    margin: 5px;

    .active {
      padding-bottom: 6px;
      padding-top: 2px;
      font-size: 12px;
      line-height: 12px;
    }
  }

  @media only screen and (max-width: $break_point_xs) {
    right: 0%;
  }
}

.stack-contain {
  width: 330px;
  background-color: $eight;
  border-radius: 25px;
  box-shadow: 0px 0px 60px -23px rgb(206, 206, 206);

  .blck {
    padding: 28px 20px;

    .btn-wrapper {
      text-align: center;
    }

    .custom {
      width: 80px;
      margin: 0 auto;
    }

    .wrp {
      display: flex;
      justify-content: space-between;
    }
  }

  .second-block {
    width: 80px;
  }
}

.bugmodal {
  background-color: #d0b695;
  width: 350px;
  padding: 25px;
  border-radius: 10px;

  @media only screen and (max-height: 500px) and (min-width: 574px) and (max-width: 1920px) {
    margin: 0 auto;
  }
}

.custom-input {
  border-top: 3px solid;
  border-left: 3px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-file-input {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 3px solid;
  border-left: 3px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;

  svg {
    width: 25px;
  }
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: auto;
  height: 100vh;

  @media only screen and (max-height: 500px) and (min-width: 574px) and (max-width: 1920px) {
    height: 100%;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.close {
  float: right;
  font-size: 20px;
  font-weight: 700;
}
