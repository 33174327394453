.withdraw-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);

  .withdraw-modal {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background-color: papayawhip;

    box-shadow: 0px 0px 6px 1px rgba($color: #000000, $alpha: 0.7);
    border: 2px solid black;
    border-radius: 10px;

    max-width: 300px;
    height: 408px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

    .inner-wrapper {
      position: relative;
      padding-top: 15px;

      .close-button {
        position: absolute;
        font-size: 18px;
        font-weight: bold;
        top: -14px;
        right: -11px;
        cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC)
            14 0,
          pointer;
      }

      img {
        min-height: 256px;
      }
    }

    p {
      margin: 15px 0px;
      font-size: 18px;
      text-align: center;
    }

    button {
      width: 100%;
      margin: auto;
    }
  }
}
